* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'PT Sans', sans-serif;
  }
 
  .home,
  .bio,
  .publications,
  .services,
  .rhythmai,
  .contact-me {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .home {
    background-image: url('./images/img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .bio-container {
    background-color: #1D2731;
    color: #fff;
    display: flex;
    padding: 3em;
  }

  .publications {
    background-image: url('./images/img-6.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .services {
    background-image: url('./images/img-7.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .contact-me {
    background-image: url('./images/img-9.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .rhythmai {
    background-image: url('./images/img-9.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  